<template>
    <div class="yx_7525">
        <div v-if="!isApp" class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
            <img @click="goback" src="../../../../static/common/logo.png" alt="" />
        </div>
        <div class="step_1">
              <div class="bgtit">
                    4000美元直送 马上行动！
                </div>
                <div class="bgtime">
                    <div>
                        <p>下次议息公布时间：</p>
                        <p>北京时间2024年11月8日02:00</p>
                    </div>
                    <div class="jytime">  <p>议息活动交易时间：</p>
                        <p>2024年11月4日-2024年11月9 日</p></div>
                </div>
            <div class="w">
                <!-- <div class="text">
                    <p>听公开课 盈利更有把握</p>
                    <p>公开课时间： 7月26日20:00-22:00</p>
                </div> -->
            </div>
        </div>
        <div class="w">
            <div class="step_2">
                <div class="title">
                    <p>议息关键解读</p>
                    <!-- <p>下次美联储利率公布时间：北京时间2024年6月13日02:00</p> -->
                </div>
                <div class="box">
                    <div class="item">
                        <div class="question">· 聚焦美联储议息：</div>
                        <div class="answer">
                            <p>美联储议息会议是（FOMC），主要内容是透过调控当下的货币政策，来达到经济成长及物价稳定两者间的平衡。</p>
                           <p>9月议息上，美联储选择降息两码，将联邦利率有效区间下修在4.75%-5%，符合市场预期。这是美联储在暴力加息并维持高利率两年后的首次降息。本次议息看点将围绕在美联储是否会继续降息，以及将降息25个基点还是50个基点。</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="question">· 如何引爆交易机会？</div>
                        <div class="answer">
                            <p><span>行情动荡引发交易机会</span></p>
                            <p>美元主导着全球金融交易市场，议息结果公布后，引发期货，股市，债市波动，给投资者创造了良好交易的机会，投资者可以根据加息减息来判断市场美元流动性，有迹可循的进行投资交易。本次最大看点在美联储接下来的降息动向，随着美国经济数据近期逐渐有放缓的迹象，预料美联储将持续性降息以为经济进行托底，避免经济衰退才能达成软着陆。
                            </p>
                            <!-- <p>在8月美联储利率决议公布后，由于鲍威尔打开了9月降息的大门，市场几乎完全定价美联储将在9月降息，美元指数有所下行，但仍稳站在104关口上方，最终收跌0.41%，报104.05。美债收益率大幅下跌，基准的10年期美债收益率跌近4%大关，收报4.0350%，两年期美债收益率收报4.266%。现货黄金延续涨势，再次涨超1%，并一度逼近2450美元大关，但未能站上此处，最终收涨1.53%，报2447.75美元/盎司。现货白银最终收涨2.17%，报28.99美元/盎司。得益于“小非农”数据与鲍威尔为9月开启降息背书，美股周三集体收涨，道指收涨0.24%，标普500指数涨1.58%，纳指涨2.63%。鲍威尔在新闻发布会上表示，美联储就业和通胀风险进入更好平衡，如果劳动力市场恶化或通胀迅速下降，美联储准备做出回应。这意味着如果就业降温，美联储可能在避免衰退的前提下调整货币政策，并表示最快9月份就会下调政策利率。
                            </p> -->
                        </div>
                    </div>
                    <div class="item">
                        <div class="question">· 选择什么交易产品？</div>
                        <div class="answer">
                            <p><span>用美元定价和结算的产品</span></p>
                            <p>首先，议息会议决定了美元的升值或贬值，这直接反应了与美元相对应货币的价值波动</p>
                            <p>例如：美元/日元，美元加息，美元流动性减少，美元相对于日元升值，日元贬值</p>
                        </div>
                    </div>
                </div>
                <div class="bottom">
                    <div class="item">美国原油</div>
                    <div class="item">美元/日元</div>
                    <div class="item">欧元/美元</div>
                    <div class="item">英镑/美元</div>
                    <div class="item">纳斯达克指数</div>
                    <div class="item">标普500指数</div>
                    <div class="item">道琼斯指数</div>
                </div>
                <div class="btnBox">
                    <div @click="getLive800">咨询客服</div>
                    <!-- <div @click="getClassRoom">下载官方APP</div> -->
                </div>
            </div>
            <div class="step_3">
                <div class="title">
                    <p>交易赚美元</p>
                    <p><span @click="showDialog = true">活动规则</span></p>
                </div>
                <div class="imgBox">
                    <img src="/subject/yx_7525/index/s5.png" alt="">
                    <img src="/subject/yx_7525/index/s6.jpg" alt="">
                    <img src="/subject/yx_7525/index/s7.png" alt="">
                </div>
                <div class="brandBox">
                    <div class="item">
                        <p>交易越多</p>
                        <p>奖励越高</p>
                    </div>
                    <div class="item">
                        <p>豪礼加持</p>
                        <p>4000美元直送</p>
                    </div>
                    <div class="item">
                        <p>超多选择</p>
                        <p>各品种任你选</p>
                    </div>
                </div>
                <div class="textBox">
                    <p>所有产品皆与美元涨跌息息相关</p>
                    <p>
                        <img src="/subject/yx_7525/index/s10.png" alt="">
                    </p>
                    <p>美元现金 ≈ 交易手数的<span>4倍!</span></p>
                </div>
                <div class="btnBox">
                    <div @click="getLive800">咨询客服</div>
                    <!-- <div @click="getClassRoom">下载官方APP</div> -->
                </div>
            </div>
            <div class="step_4">
                <div class="title">
                      <p>议息数据直击</p>
                    <p> 
当前议息政策:北京时间2024年11月7日02:00，美联储料将继上个月降息后再次降息。据CME美联储观察，美联储11月降息25个基点的概率为99%，降息50个基点的概率为1%。随着近期美国经济数据转弱与失业率上升，市场定调美联储年内将降息一共四次，市场更是定调本轮降息周期至少将延续至明年年中，最终利率可能将回落至3.5%-3.75%区间，相较于之前市场过度乐观至2%开头有所收殓。
</p>
                    <p>各国央行利率</p>
                </div>
                <div style="overflow-x:auto;margin-top: 20px;">
                    <img src="/subject/yx_7525/index/table2.png" style="width:186%" class="imgtable" alt="">
                    <!-- 表格 -->
                    <!-- <div class="tabs">
                        <table>
                            <tr class="biaoti">
                                <th >央行</th>
                                <th>当前利率</th>
                                <th>下次预测</th>
                                <th>最近加降息(非0基点)</th>
                                <th>历史峰值</th>
                                <th>历史最低</th>
                                <th>CPI最新值</th>
                            </tr>
                            <tr class="bodys">
                                <th>
                                    <div class="guoqi">
                                        <img src="/subject/yx_7525/wap/qi01.png" alt="">
                                        <div class="guojia">
                                            <p class="guoji">美联储</p>
                                            <p>FED</p>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <p class="numbers">5.5</p>
                                    <p class="times">2023-09-20</p>
                                </th>
                                <th>
                                    <p class="numbers active">5.5</p>
                                    <p class="times">2023-11-01</p>
                                </th>
                                <th>
                                    <p class="numbers">+25</p>
                                    <p class="times">2023-07-26</p>
                                </th>
                                <th>
                                    <p class="numbers active">20</p>
                                    <p class="times">1980-03-04</p>
                                </th>
                                <th>
                                    <p class="numbers">0-0.25</p>
                                    <p class="times">2008-12-27</p>
                                </th>
                                <th>
                                    <p class="numbers">3.7</p>
                                </th>
                            </tr>
                            <tr class="bodys">
                                <th>
                                    <div class="guoqi">
                                        <img src="/subject/yx_7525/wap/qi02.png" alt="">
                                        <div class="guojia">
                                            <p class="guoji">欧洲央行</p>
                                            <p>ECB</p>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <p class="numbers">4.5</p>
                                    <p class="times">2023-09-14</p>
                                </th>
                                <th>
                                    <p class="numbers active">4.5</p>
                                    <p class="times">2023-10-26</p>
                                </th>
                                <th>
                                    <p class="numbers">+25</p>
                                    <p class="times">2023-09-14</p>
                                </th>
                                <th>
                                    <p class="numbers active">4.75</p>
                                    <p class="times">2000-10-05</p>
                                </th>
                                <th>
                                    <p class="numbers">0.00</p>
                                    <p class="times">2016-03-16</p>
                                </th>
                                <th>
                                    <p class="numbers">4.3</p>
                                </th>
                            </tr>
                            <tr class="bodys">
                                <th>
                                    <div class="guoqi">
                                        <img src="/subject/yx_7525/wap/qi03.png" alt="">
                                        <div class="guojia">
                                            <p class="guoji">日本央行</p>
                                            <p>BOJ</p>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <p class="numbers">-0.1</p>
                                    <p class="times">2023-09-22</p>
                                </th>
                                <th>
                                    <p class="numbers active">-0.1</p>
                                    <p class="times">2023-10-31</p>
                                </th>
                                <th>
                                    <p class="numbers">-20</p>
                                    <p class="times">2016-01-29</p>
                                </th>
                                <th>
                                    <p class="numbers active">0.30</p>
                                    <p class="times">2008-10-31</p>
                                </th>
                                <th>
                                    <p class="numbers">-0.10</p>
                                    <p class="times">2016-01-29</p>
                                </th>
                                <th>
                                    <p class="numbers">3.2</p>
                                </th>
                            </tr>
                            <tr class="bodys">
                                <th>
                                    <div class="guoqi">
                                        <img src="/subject/yx_7525/wap/qi04.png" alt="">
                                        <div class="guojia">
                                            <p class="guoji">英国央行</p>
                                            <p>BOE</p>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <p class="numbers">5.25</p>
                                    <p class="times">2023-09-21</p>
                                </th>
                                <th>
                                    <p class="numbers active">5.25</p>
                                    <p class="times">2023-10-02</p>
                                </th>
                                <th>
                                    <p class="numbers">+25</p>
                                    <p class="times">2023-08-03</p>
                                </th>
                                <th>
                                    <p class="numbers active">17</p>
                                    <p class="times">1979-11-15</p>
                                </th>
                                <th>
                                    <p class="numbers">0.1</p>
                                    <p class="times">2020-03-26</p>
                                </th>
                                <th>
                                    <p class="numbers">6.7</p>
                                </th>
                            </tr>
                            <tr class="bodys">
                                <th>
                                    <div class="guoqi">
                                        <img src="/subject/yx_7525/wap/qi05.png" alt="">
                                        <div class="guojia">
                                            <p class="guoji">瑞士央行</p>
                                            <p>SNB</p>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <p class="numbers">1.75</p>
                                    <p class="times">2023-09-21</p>
                                </th>
                                <th>
                                    <p class="numbers active">1.75</p>
                                    <p class="times">2023-12-14</p>
                                </th>
                                <th>
                                    <p class="numbers">+25</p>
                                    <p class="times">2023-06-22</p>
                                </th>
                                <th>
                                    <p class="numbers active">3.5</p>
                                    <p class="times">2000-02-03</p>
                                </th>
                                <th>
                                    <p class="numbers">-0.75</p>
                                    <p class="times">2015-01-15</p>
                                </th>
                                <th>
                                    <p class="numbers">1.7</p>
                                </th>
                            </tr>
                            <tr class="bodys">
                                <th>
                                    <div class="guoqi">
                                        <img src="/subject/yx_7525/wap/qi06.png" alt="">
                                        <div class="guojia">
                                            <p class="guoji">欧洲联储</p>
                                            <p>RBA</p>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <p class="numbers">4.1</p>
                                    <p class="times">2023-10-03</p>
                                </th>
                                <th>
                                    <p class="numbers active">4.1</p>
                                    <p class="times">2023-11-07</p>
                                </th>
                                <th>
                                    <p class="numbers">+25</p>
                                    <p class="times">2023-05-02</p>
                                </th>
                                <th>
                                    <p class="numbers active">17.00</p>
                                    <p class="times">1990-01-22</p>
                                </th>
                                <th>
                                    <p class="numbers">0.75</p>
                                    <p class="times">2019-10-01</p>
                                </th>
                                <th>
                                    <p class="numbers">6</p>
                                </th>
                            </tr>
                            <tr class="bodys">
                                <th>
                                    <div class="guoqi">
                                        <img src="/subject/yx_7525/wap/qi07.png" alt="">
                                        <div class="guojia">
                                            <p class="guoji">加南大央行</p>
                                            <p>BOC</p>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <p class="numbers">5</p>
                                    <p class="times">2023-09-06</p>
                                </th>
                                <th>
                                    <p class="numbers active">5</p>
                                    <p class="times">2023-10-25</p>
                                </th>
                                <th>
                                    <p class="numbers">+25</p>
                                    <p class="times">2022-12-07</p>
                                </th>
                                <th>
                                    <p class="numbers active">8.06</p>
                                    <p class="times">1995-02-23</p>
                                </th>
                                <th>
                                    <p class="numbers">0.25</p>
                                    <p class="times">2009-04-21</p>
                                </th>
                                <th>
                                    <p class="numbers">4</p>
                                </th>
                            </tr>
                            <tr class="bodys">
                                <th>
                                    <div class="guoqi">
                                        <img src="/subject/yx_7525/wap/qi08.png" alt="">
                                        <div class="guojia">
                                            <p class="guoji">新西兰联储</p>
                                            <p>RBNZ</p>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <p class="numbers">5.5</p>
                                    <p class="times">2023-10-04</p>
                                </th>
                                <th>
                                    <p class="numbers active">5.5</p>
                                    <p class="times">2023-11-29</p>
                                </th>
                                <th>
                                    <p class="numbers">+25</p>
                                    <p class="times">2023-05-24</p>
                                </th>
                                <th>
                                    <p class="numbers active">8.25</p>
                                    <p class="times">2007-07-26</p>
                                </th>
                                <th>
                                    <p class="numbers">0.25</p>
                                    <p class="times">2020-03-16</p>
                                </th>
                                <th>
                                    <p class="numbers">6</p>
                                </th>
                            </tr>
                        </table>
                    </div> -->
                </div>
                <div class="btnBox">
                    <div @click="getLive800">咨询客服</div>
                    <!-- <div @click="getClassRoom">下载官方APP</div> -->
                </div>
            </div>
            <div class="step_5">
                <div class="title">
                    <p>交易近距离</p>
                    <p>直击交易现场 近距离接触赚钱的机会</p>
                </div>
                <div class="box">
                    <div class="textBox">
                        <p>咨询客服下载官方APP</p>
                        <P>发现更多智富可能</P>
                    </div>
                    <div class="btnBox">
                        <div @click="getLive800">咨询客服</div>
                    </div>
                </div>
            </div>
        </div>
        <contactusVue v-if="!isApp"></contactusVue>
        <div v-if="showDialog" class="dialog">
            <img class="closer" @click="showDialog = false" src="/subject/feinong/wap/guanbi2.png" alt="">
            <ul class="dialogContent">
                <li>
                    <span>活动对象：</span>
                    本公司所有新老用户
                </li>
                <li>
                    <span>活动交易时间：</span>
                    2024年11月4日06:00-2024年11月9日06:00
                </li>
                <li>
                    <span>兑奖截止时间：</span>
                     2024年11月25 日00:00（逾期失效）
                </li>
                <li>交易送金，升降任你赚！交易越多赚越多，最高领4000美元现金！</li>
                <li>
                    <span>活动规则：</span><br />
                    活动期间内，凡在本公司的APP或MT交易平台交易指定品种，完成指定交易手数，即可兑换相应美元现金奖励，交易时间结束后，统一开始兑换，限兑奖一次；
                </li>
                <li>
                    <span>活动参与品种：</span><br />
                    美国原油、 美元/日元、 欧元/美元、英镑/美元、 纳斯达克指数、 标普500指数、 道琼斯指数
                </li>
                <li>
                    <span>交易奖励：</span><br />
                     <table>
                        <tr>
                            <th>交易品种</th>
                            <th>赠送条件</th>
                            <th>美元现金奖励</th>
                        </tr>
                        <tr>
                            <th rowspan="11">
                                <p>美国原油</p>
                                <p>美元/日元</p>
                                <p>欧元/美元</p>
                                <p>英镑/美元</p>
                                <p>纳斯达克指数</p>
                                <p>标普500指数</p>
                                <p>道琼斯指数</p>
                            </th>
                            <th>≥3手</th>
                            <th>6美元</th>
                        </tr>
                        <tr>
                            <th>≥5手</th>
                            <th>12美元</th>
                        </tr>
                        <tr>
                            <th>≥10手</th>
                            <th>25美元</th>
                        </tr>
                        <tr>
                            <th>≥20手</th>
                            <th>60美元</th>
                        </tr>
                        <tr>
                            <th>≥50手</th>
                            <th>180美元</th>
                        </tr>
                        <tr>
                            <th>≥80手</th>
                            <th>300美元</th>
                        </tr>
                        <tr>
                            <th>≥100手</th>
                            <th>400美元</th>
                        </tr>
                        <tr>
                            <th>≥150手</th>
                            <th>600美元</th>
                        </tr>
                        <tr>
                            <th>≥200手</th>
                            <th>800美元</th>
                        </tr>
                        <tr>
                            <th>≥500手</th>
                            <th>2000美元</th>
                        </tr>
                        <tr>
                            <th>≥1000手</th>
                            <th>4000美元</th>
                        </tr>
                    </table>
                </li>
                <li>
                    <span>领取方式：</span><br />
                    1.议息奖励在“APP”→“我的”→“活动中心”→“议息活动”中领取； <br />
                    2.活动只计算活动时间内的交易手数，奖励会在活动结束后开启兑换； <br />
                    3.奖励后的美元可直接提现，亦可继续用于交易；<br />
                    4.美元现金奖励需在兑奖截止日期前领取，逾期失效；
                </li>
                <li>
                    <span>注意事项</span><br />
                    1.客户参与活动需在本公司开立真实交易账户；<br />
                    2.本活动仅限于活动指定品种的交易手数，手数计算以开平仓为准；<br />
                    3.各账户层级用户均可参加本活动，美元现金奖励将依据活动交易时间结束后的最终平仓手数发放；<br />
                    4.本公司保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。
                </li>
            </ul>
        </div>
        <div class="dark" v-if="showDialog"></div>
        
    </div>
</template>
<script>
import ContactusVue from "../../Contactus.vue";
import { getYiXiTime } from "../../../../api/subject/yx_7525";
import { parseTime } from "../../../../utils/tools"
export default {
    name: 'feinongWap',
    components: {
        ContactusVue,
    },
    data() {
        return {
            showDialog: false,
            navBarFixed: false,
            scroll: '',
            isApp: false,
            activityTime: {
                starttime: null,
                endtime: null,
                prizedeadline: null,
            },
        }
    },
    created() {
        this.$emit("controlShow", true);
        // 判断是从A前还是A后跳转过来
        if (!sessionStorage.getItem("craig")) {
            if (!this.$route.query.craig) {
                sessionStorage.setItem("craig", window.location.origin);
            } else {
                sessionStorage.setItem("craig", this.$route.query.craig);
            }
        }
        if (this.$route.query.isApp) {
            this.isApp = true;
        }
        // 获取 活动 时间
        var data = {
            Platform: '1',
            ActivityId: '320',
            Account: undefined
        }
        getYiXiTime(JSON.stringify(data)).then(res => {
            this.activityTime.starttime = parseTime((new Date(res.content.starttime + 28800000)), 'yyyy-MM-dd hh:mm:ss')
            this.activityTime.endtime = parseTime((new Date(res.content.endtime + 28800000)), 'yyyy-MM-dd hh:mm:ss');
            this.activityTime.prizedeadline = parseTime((new Date(res.content.prizedeadline + 28800000)), 'yyyy-MM-dd hh:mm:ss');
        })
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    methods: {
        // 返回上级页面
        goback() {
            window.location.href = sessionStorage.getItem("craig");
        },
        // 控制顶部的定位
        handleScroll() {
            this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
            if (this.scroll > 90) {
                this.navBarFixed = true;
            } else {
                this.navBarFixed = false;
            }
        },
        getLive800() {
            window.open(
                this.$parent.live800UrlUc
            );
        },
        getClassRoom() {
            this.$router.push({
                name: "classRoom",
            });
        },
        openDownload() {
            window.open('https://oss.0790jiaxiao.com/HS/software/hs_9999.apk')
        },
    }
}
</script>
<style lang="less" scoped>
.tabbar {
    width: 100%;
    background-color: #ffffff;

    img {
        margin: 10px 30px;
        width: 130px;
    }
}

.navBarFixed {
    position: fixed;
    top: 0;
    z-index: 999;
}

.yx_7525 {
    width: 100%;
    background-color: #c1e3fc;
}

.step_1 {
    width: 100%;
    height: 417px;
    margin: 0 auto;
    box-sizing: border-box;
     background: url("/subject/yx_7525/wap/banners.png") no-repeat center;
    background-size: 100% 100%;
    position: relative;
    z-index: 9;
    .bgtit{
        background: url("/subject/yx_7525/wap/bgtit.png") no-repeat center;
        background-size: 100% 100%;
        width: 232px;
        height: 36px;
        position: absolute;
        bottom: 189px;
        left: 50%;
        transform: translateX(-50%);
        line-height: 36px;
        color: #fff;
        text-align: center;
    }
    .bgtime{
        text-align: center;
        width: 232px;
        height: 36px;
        position: absolute;
        bottom: 140px;
        left: 50%;
        transform: translateX(-50%);
        color:rgb(64, 94, 162)
    }
    .jytime{
        margin-top: 10px;
    }
    .text {
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
    }
}

.w {
    width: 94%;
    margin: 0 auto;
}

.step_2 {
    width: 100%;
    // height: 1010px;
    box-sizing: border-box;
    background: linear-gradient(to top, #dae3ff, #ffffff);
    border-radius: 20px;
    margin-top: -23px;
    position: relative;
    z-index: 23;

    .title {
        height: 90px;
        box-sizing: border-box;
        text-align: center;
        padding-top: 22px;

        p {
            &:nth-child(1) {
                color: #334d95;
                font-weight: 600;
                font-size: 28px;
                margin-bottom: 10px;
            }

            &:nth-child(2) {
                color: #334d95;
                font-size: 14px;
            }
        }
    }

    .box {
        width: 94%;
        box-sizing: border-box;
        margin: 0 auto;

        .item {
            margin-top: 16px;

            .question {
                color: #334d95;
                font-size: 18px;
            }

            .answer {
                width: 100%;
                box-sizing: border-box;
                padding: 13px 16px;
                color: #334d95;
                font-size: 14px;
                line-height: 20px;
                background-color: #dceaff;
                border: 1px solid #334d95;
                border-radius: 10px;
                margin-top: 12px;

                span {
                    font-weight: 600;
                }
            }
        }
    }

    .bottom {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 15px;

        .item {
            font-size: 14px;
            border-radius: 4px;
            background: linear-gradient(to top, #f9e7cd, #ffffff);
            padding: 4px 7px;
            color: #a5542e;
            margin: 5px 2px;

            &:nth-child(2n) {
                background: linear-gradient(to top, #c1cfff, #ffffff);
                color: #334d95;
            }
        }
    }

    .btnBox {
        width: 80%;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 25px;
        div {
            width: 130px;
            height: 35px;
            border-radius: 10px;
            text-align: center;
            line-height: 35px;
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
            background: linear-gradient(to top, #c1cfff, #ffffff);
            color: #334d95;
            margin: 0 auto;
            margin-bottom: 10px;
            &:nth-child(2) {
                background: linear-gradient(to top, #416ade, #9fa9ff);
                color: #fff;
            }
        }
    }
}

.step_3 {
    width: 100%;
    height: 505px;
    box-sizing: border-box;
    background: linear-gradient(to top, #dae3ff, #ffffff);
    border-radius: 20px;
    margin-top: 20px;

    .title {
        height: 90px;
        box-sizing: border-box;
        text-align: center;
        padding-top: 22px;

        p {
            &:nth-child(1) {
                background-image: -webkit-linear-gradient(top, #ff9e9e, #eb4141);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 600;
                font-size: 28px;
                margin-bottom: 10px;
            }

            &:nth-child(2) {
                color: #334d95;
                font-size: 16px;
                text-align: center;

                span {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }

    .imgBox {
        width: 94%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: end;
        margin-top: 24px;

        img {
            height: 115px;

            &:nth-child(2) {
                height: 125px;
            }
        }
    }

    .brandBox {
        width: 94%;
        margin: 0 auto;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 20px;

        .item {
            width: 100px;
            height: 65px;
            text-align: center;
            border: 1px solid #a0aaff;
            font-size: 14px;
            background-color: #fff;
            color: #334d95;
            box-sizing: border-box;
            padding-top: 10px;
            border-radius: 10px;

            p {
                &:nth-child(1) {
                    font-weight: 600;
                    margin-bottom: 4px;
                }
            }
        }
    }

    .textBox {
        margin-top: 20px;
        text-align: center;
        color: #334d95;
        font-size: 14px;

        p {
            &:nth-child(1) {
                margin-bottom: 5px;
            }

            &:nth-child(3) {
                margin-top: -12px;
            }
        }

        img {
            height: 18px;
            display: inline-block;
            position: relative;
            left: -20px;
        }

        span {
            color: #ff5a3d;
            font-weight: 600;
            font-size: 24px;
        }
    }

    .btnBox {
        width: 80%;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 25px;
        margin-bottom: 10px;

        div {
            width: 130px;
            height: 35px;
            border-radius: 10px;
            text-align: center;
            line-height: 35px;
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
            background: linear-gradient(to top, #c1cfff, #ffffff);
            color: #334d95;
            margin: 0 auto;
            &:nth-child(2) {
                background: linear-gradient(to top, #416ade, #9fa9ff);
                color: #fff;
            }
        }
    }

}

.step_4 {
    width: 100%;
    padding-bottom: 30px;
    box-sizing: border-box;
    background: linear-gradient(to top, #dae3ff, #ffffff);
    border-radius: 20px;
    margin-top: 20px;

    .title {
        box-sizing: border-box;
        text-align: center;
        padding-top: 22px;

        p {
            &:nth-child(1) {
               color: #334d95;
                font-weight: 600;
                font-size: 28px;
                margin-bottom: 10px;
            }

            &:nth-child(2) {
                color: #334d95;
                font-size: 14px;
                text-align: center;
                font-weight: 400;

            }
            &:nth-child(3) {
                color: #334d95;
                font-size: 17px;
                text-align: center;
                font-weight: 600;
                margin-top: 10px;
            }
        }
    }
    .tabs {
                width:208%;
                // margin: 0 auto;
                background: linear-gradient(to top, #dae3ff, #ffffff);
                table {
                        width: 100%;
                        border-collapse: collapse; 
                        // border: 1px solid #ccc;
                        th, td {
                        border-bottom: 1px solid #ccc; /* 表头和单元格边框 */
                        padding: 8px;
                        text-align: center;
                        }
                        .biaoti {
                    th {
                        font-size: 16px;
                        color: #fc8716;
                        font-weight: 600;
                        white-space: nowrap;
                        
                        }
                    }
                    .bodys {
                    
                    .guoqi {
                        display: flex;
                        width: 180px;
                        font-size: 16px;
                        img {
                        width: 65px;
                        height: 45px;
                        }
                        .guojia {
                            margin-left: 15px;
                            margin-right: 20px;
                            text-align: left;
                        }
                    }
                    .numbers {
                        font-size: 14px;
                    } 
                    .active {
                        color: #ff4242;
                    } 
                    .times {
                        font-size: 12px;
                        color: #ccc;
                        white-space: nowrap;
                    }
                    }  
                    
                    }
            }

    .box {
        margin-top: 20px;

        .left {
            width: 94%;
            margin: 0 auto;

            .textBox {
                width: 100%;

                .item {
                    width: 100%;
                    height: 35px;
                    border-radius: 10px;
                    border: 1px solid #a0aaff;
                    line-height: 35px;
                    display: flex;
                    justify-content: space-between;
                    color: #334d95;
                    margin-top: 10px;
                    font-size: 12px;

                    div {
                        &:nth-child(1) {
                            width: 100px;
                            text-align: center;
                            background-color: #dceaff;
                            border-radius: 10px;
                            border-right: 1px solid #a0aaff;
                        }

                        &:nth-child(2) {
                            width: 450px;
                            text-align: center;
                        }
                    }
                }
            
            }

            .bottom {
                width: 96%;
                margin: 0 auto;
                margin-top: 18px;
                text-align: center;

                p {
                    font-size: 14px;
                    color: #334d95;
                    margin-bottom: 7px;

                    &:nth-child(1) {
                        font-size: 17px;
                        font-weight: 600;
                    }

                    span {
                        font-size: 17px;
                        font-weight: 600;
                        color: #ff5a3d;

                    }
                }
            }
        }

        .right {
            width: 96%;
            margin: 0 auto;
            text-align: center;
            color: #334d95;

            p {
                font-size: 14px;
                margin-top: 4px;

                &:nth-child(1) {
                    font-size: 17px;
                    font-weight: 600;
                    margin-bottom: 10px;
                    margin-top: 15px;
                }
            }

            div {
                width: 100%;
                font-size: 14px;
                text-align: center;
                background-color: #fff;
                border-radius: 10px;
                border: 1px solid #a0aaff;
                box-sizing: border-box;
                line-height: 26px;
                padding: 15px 0;
            }
        }
    }

    .imgBox {
        text-align: center;
        margin-top: 15px;

        img {
            height: 18px;
            position: relative;
            left: -22px;
        }
    }

    .btnBox {
        width: 80%;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 20px;

        div {
            width: 130px;
            height: 35px;
            border-radius: 10px;
            text-align: center;
            line-height: 35px;
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
            background: linear-gradient(to top, #c1cfff, #ffffff);
            color: #334d95;
            margin: 0 auto;
            &:nth-child(2) {
                background: linear-gradient(to top, #416ade, #9fa9ff);
                color: #fff;
            }
        }
    }


}

.step_5 {
    width: 100%;
    height: 190px;
    box-sizing: border-box;
    background: linear-gradient(to top, #dae3ff, #ffffff);
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 50px;

    .title {
        height: 90px;
        box-sizing: border-box;
        text-align: center;
        padding-top: 22px;

        p {
            &:nth-child(1) {
               color: #334d95;
                font-weight: 600;
                font-size: 28px;
                margin-bottom: 10px;
            }

            &:nth-child(2) {
                color: #334d95;
                font-size: 17px;
                text-align: center;
                font-weight: 600;

            }
        }
    }

    .box {
        width: 80%;
        margin: 0 auto;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;


        .textBox {
            font-size: 14px;
            color: #334d95;
            line-height: 18px;
        }

        .btnBox {
            width: 40%;
            margin: 0 auto;
            text-align: center;
            div {
                width: 100%;
                height: 35px;
                border-radius: 10px;
                text-align: center;
                line-height: 35px;
                cursor: pointer;
                font-size: 14px;
                font-weight: 600;
                background: linear-gradient(to top, #416ade, #9fa9ff);
                color: #fff;
                margin: 0 auto;
            }
        }
    }
}

.dialog {
    position: relative;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    height: 480px;
    box-shadow: 0px 0px 95px 0px rgba(187, 188, 192, 0.1);
    background-color: #fdf5e6;
    border-radius: 15px;
    overflow-y: scroll;
    z-index: 99999999;

    .closer {
        width: 23px;
        height: auto;
        position: absolute;
        top: 5px;
        right: 5px;
    }

    .dialogContent {
        box-sizing: border-box;
        padding: 10px;
        overflow: hidden;

        li {
            font-size: 14px;
            color: #a86e4f;
            line-height: 24px;

            span {
                font-weight: 600;
                color: #e34a3f;
            }

            img {
                width: 100%;
                height: auto;
                margin: 20px 0;
            }

            table {
                width: 100%;
                border: 1px solid #e34a3f;
                border-radius: 10px;

                tr {
                    th {
                        text-align: center;
                        font-weight: 500;
                        line-height: 24px;
                        border-bottom: 1px solid #d2a765;
                        border-right: 1px solid #d2a765;
                        padding: 5px;
                    }

                    th:last-child {
                        border-right: 1px solid transparent;
                    }
                }

                tr:nth-child(1) {
                    th {
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

.dark {
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .5;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 101;
}


</style>